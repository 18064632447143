import React, {useEffect} from "react";
import axios from "axios";
import {IntlProvider, loadMessages, LocalizationProvider} from "@progress/kendo-react-intl";
import {Input} from "@progress/kendo-react-inputs";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {filterBy} from "@progress/kendo-data-query";
import Back from "./Back";
import Spinner from "./Spinner";
import esMessages from "../es.json";
import PopUpDropTask from "./PopUps/PopUpDropTask";
import PopUpDropDetailTask from "./PopUps/PopUpDropDetailTask";
loadMessages(esMessages, 'es-ES');

function Task (props){
    let userId = props.location.state.userId;
    let projectId = props.location.state.projectId;
    let projectName = props.location.state.projectName;
    let userName = props.location.state.userName;
    let locationId = props.location.state.locationId;
    let asignationId = props.location.state.asignationId;
    // let taskLocationId = props.location.state.taskLocationId;
    const menu = "Detalles tareas";
    const [data, setData] = React.useState([]);
    const [loader, setLoader] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(5);
    const [filter, setFilter] = React.useState({
        logic: "or",
        filters: [
            { field: "asignationId", operator: "contains", value: "" },
            { field: "taskLocationId", operator: "contains", value: "" },
            { field: "taskDetail", operator: "contains", value: "" },
            { field: "taskNameId", operator: "contains", value: "" },
            { field: "statusView", operator: "contains", value: "" }
        ]
    })

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-detail-task',{userId: userId, asignationId: asignationId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
            });
    }, [userId, asignationId]);

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "asignationId", operator: "contains", value: e.target.value },
                    { field: "taskLocationId", operator: "contains", value: e.target.value },
                    { field: "taskDetail", operator: "contains", value: e.target.value },
                    { field: "taskNameId", operator: "contains", value: e.target.value },
                    { field: "statusView", operator: "contains", value: e.target.value }
                ]
            }
        );
    }

    const DropTask = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <PopUpDropTask
                        props={{
                            userId: userId,
                            projectId: projectId,
                            locationId: locationId,
                            menu: menu,
                            userName: userName,
                            taskLocationId: props.dataItem.taskLocationId,
                            asignationId: props.dataItem.asignationId,
                            taskId: props.dataItem.taskId,
                            taskDetailId: props.dataItem.taskDetail
                        }}
                    />
                </div>
            </td>
        )
    }

    const DropDetailTask = (props) => {
        console.log(props)
        if (props.dataItem.taskDetail===null){
            return (
                <td>

                </td>
            )
        }else {
            return (
                <td>
                    <div style={{textAlign: 'center', alignContent: 'center'}}>
                        <PopUpDropDetailTask
                            props={{
                                userId: userId,
                                projectId: projectId,
                                locationId: locationId,
                                menu: menu,
                                userName: userName,
                                taskLocationId: props.dataItem.taskLocationId,
                                asignationId: props.dataItem.asignationId,
                                taskId: props.dataItem.taskId,
                                taskNameId: props.dataItem.taskNameId,
                                taskDetailId: props.dataItem.taskDetail
                            }}
                        />
                    </div>
                </td>
            )
        }
    }

    return(
        <LocalizationProvider language="es-ES">
            <IntlProvider locale="es" >
                <div style={{width: '100%'}}>
                    <h5 style={{textAlign: 'center', alignContent: 'center', color: '#3F3344'}}>{"Proyecto : "+projectId + " - " + projectName}</h5>
                    <h3 style={{textAlign: 'center', alignContent: 'center', color: '#FF995B'}}>Detalles Tareas</h3>
                    <Input
                        style={{width: '100%'}}
                        className={'full-space'}
                        placeholder={'Buscar'}
                        onChange={searchEvent}/>
                        <br/>
                        <br/>
                    <Grid
                        style={{
                            height: '100%',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            textAlignLast: 'center',
                        }}
                        data={filterBy(data, filter).slice(skip,take + skip)}
                        skip={skip}
                        take={take}
                        total={data.length}
                        pageable={{buttonCount: 4, pageSizes: true}}
                        onPageChange={pageChange}
                        resizable={true}
                    >
                        <GridColumn title={'Asignación'} field={'asignationId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Id Ubicacion Tarea'} field={'taskLocationId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Id Detalle Tarea'} field={'taskDetail'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Tarea'} field={'taskNameId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Estatus Tarea'} field={'statusView'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Eliminar tarea'} cell={DropTask} filterable={false} width={'auto'}/>
                        <GridColumn title={'Eliminar detalle tarea'} cell={DropDetailTask} filterable={false} width={'auto'}/>
                    </Grid>
                    <Back/>
                    {loader && <Spinner/>}
                </div>
            </IntlProvider>
        </LocalizationProvider>
    );
}
export default Task;