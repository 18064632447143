import React, {useEffect} from 'react';
import axios from 'axios';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Spinner from './Spinner';
import {filterBy} from "@progress/kendo-data-query";
import {Input} from "@progress/kendo-react-inputs";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../es.json';
import AppsIcon from '@material-ui/icons/Apps';
import {Link} from 'react-router-dom';
import PopUpDetail from "./PopUps/PopUpDetail";
import Back from "./Back";
loadMessages(esMessages, 'es-ES');

// let userName = "";
// console.log(userName);

function Detail(props) {
    let userId = props.location.state.userId;
    // let userName = props.location.state.userName;
    let projectId = props.location.state.projectId;
    let projectName = props.location.state.projectName;
    const menu = "Estatus ubicaciones";
    const [data, setData] = React.useState([]);
    const [userName, setUserName] = React.useState("");
    const [loader, setLoader] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(5);
    const [filter, setFilter] = React.useState({
        logic: "or",
        filters: [
            { field: "locationId", operator: "contains", value: "" },
            { field: "locationName", operator: "contains", value: "" },
            { field: "asignationId", operator: "contains", value: "" },
            { field: "pax", operator: "contains", value: "" },
            {field: "statusVista", operator: "contains", value: ""}
        ]
    })

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-locations',{userId: userId, projectId: projectId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                getUserName();
                setLoader(false);
            });
    }, [userId, projectId]);



    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "locationId", operator: "contains", value: e.target.value },
                    { field: "locationName", operator: "contains", value: e.target.value },
                    { field: "asignationId", operator: "contains", value: e.target.value },
                    { field: "pax", operator: "contains", value: e.target.value },
                    {field: "statusVista", operator: "contains", value: e.target.value}
                ]
            }
        );
    }

    const getUserName = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-user-name', {user_id: userId})
            .then((res) => {
                setUserName(res.data.response[0].userName);

            })
            .catch(() => {
                setUserName("");
            })
            .finally(() => {
                // console.log(userName);
            });
    }

    const statusAsign = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <PopUpDetail
                        props={{
                            userId: userId,
                            projectId: projectId,
                            projectName: projectName,
                            menu: menu,
                            userName: userName,
                            locationId: props.dataItem.locationId,
                            locationName: props.dataItem.locationName,
                            asignationId: props.dataItem.asignationId,
                            pax: props.dataItem.pax,
                            taskLocationId: props.dataItem.taskLocationId,
                            taskId: props.dataItem.taskId,
                            status: props.dataItem.status,
                            statusVista: props.dataItem.statusVista
                        }}
                    />
                </div>
            </td>
        )
    }

    const details = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <Link to={{
                        pathname: '/task',
                        state: {
                            userId: userId,
                            projectId: projectId,
                            projectName: projectName,
                            userName: userName,
                            locationId: props.dataItem.locationId,
                            locationName: props.dataItem.locationName,
                            asignationId: props.dataItem.asignationId,
                            pax: props.dataItem.pax,
                            taskLocationId: props.dataItem.taskLocationId,
                            taskId: props.dataItem.taskId,
                        }
                    }}>
                        <AppsIcon style={{color: '#FF995B'}}>
                            <span style={{color: '#FF995B', cursor: 'pointer'}}>{props.dataItem.audit}</span>
                        </AppsIcon>
                    </Link>
                </div>
            </td>
        );
    }

    return(
        <LocalizationProvider language="es-ES">
            <IntlProvider locale="es" >
                <div style={{width: '100%'}}>
                    <h5 style={{textAlign: 'center', alignContent: 'center', color: '#3F3344'}}>{"Proyecto : "+projectId + " - " +projectName}</h5>
                    <h3 style={{textAlign: 'center', alignContent: 'center', color: '#FF995B'}}>{"Estatus Ubicaciones"}</h3>
                    <Input
                        style={{width: '100%'}}
                        className={'full-space'}
                        placeholder={'Buscar'}
                        onChange={searchEvent}/>
                        <br/>
                        <br/>
                    <Grid
                        style={{
                            height: '500px',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            textAlignLast: 'center',
                        }}
                        data={filterBy(data, filter).slice(skip,take + skip)}
                        skip={skip}
                        take={take}
                        total={data.length}
                        pageable={{buttonCount: 4, pageSizes: true}}
                        onPageChange={pageChange}
                        resizable={true}
                    >
                        <GridColumn title={'Id Ubicacion'} field={'locationId'} width={'auto'}/>
                        <GridColumn title={'Nombre Ubicación'} field={'locationName'} filterable={false} width={'auto'} />
                        <GridColumn title={'Asignación'} field={'asignationId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'PAX'} field={'pax'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Estatus Campo'} field={'statusVista'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Nuevo Estatus'} cell={statusAsign} filterable={false} width={'auto'}/>
                        <GridColumn title={'Detalles'} cell={details} filterable={false} width={'auto'}/>
                    </Grid>
                    <Back/>
                    {loader && <Spinner/>}
                </div>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default Detail;