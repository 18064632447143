import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

let infoDelete = 5;
let infopermit = 0;
let reasons = [];
let funcion = "";
let bit = 5;
let userName = "";
let reason = 10;

export default function PopUpDropDetailTask(props){
    const classes = useStyles();
    let userId = props.props.userId;
    let projectId = props.props.projectId;
    let locationId = props.props.locationId;
    let asignationId = props.props.asignationId;
    let taskLocationId = props.props.taskLocationId;
    let taskId = props.props.taskId;
    let status = props.props.status;
    let menu = props.props.menu;
    let taskDetailId = props.props.taskDetailId;
    const [open, setOpen] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [openDoneMsg, setOpenDoneMsg] = React.useState(false);
    const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
    const [openReason, setOpenReasons] = React.useState(false);
    const [textAreaMessage, setTextAreaMessage] = React.useState(false);
    const [reasonMessage, setReasonMessage] = React.useState(false);
    const [reason1, setReason1] = React.useState(10);
    const [textArea, setTextArea] = React.useState("");

    const handleClose = () => {
        setOpen(false);
        setOpenReasons(false);
        setTextArea("");
        setReason1(10);
        reason=(10);
    };

    const closeSnackBar = () => {
        setOpenSnackBar(false);
        setOpenErrorMsg(false);
        setOpenDoneMsg(false);
        setTextAreaMessage(false);
        setReasonMessage(false);
    }

    const afterPermits = (infopermit, userId) => {
        if(parseInt(infopermit) == parseInt(userId)){
            setOpen(true);
        }else{
            setOpenSnackBar(true);
        }
    }

    const getPermits = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-permits', {user_id: userId, project_id: projectId})
            .then((res) => {
                infopermit = res.data.response[0].userId;
            })
            .catch(() => {
                infopermit = 0;
            })
            .finally(() => {
                getUserName();
                afterPermits(infopermit, userId);
            });
    }

    const getUserName = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-user-name', { user_id: userId})
            .then((res) => {
                userName = res.data.response[0].userName;
            })
            .catch(() => {
                userName = "";
            })
            .finally(() => {

            });
    }

    const getReasons = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-reasons', {user_id: userId})
            .then((res) => {
                reasons = res.data.response;
            })
            .catch(() => {
                reasons = [];
            })
            .finally(() => {
                setOpenReasons(true);
            });
    }

    const changeTextArea = (e) => {
        setTextArea(e.target.value);
    }

    const setReasons = (e) => {
        setReason1(e.target.value);
        reason=(e.target.value);
    }

    const droptask = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/delete-task-detail', {task_detail_id: taskDetailId, user_id: userId})
            .then((res) => {
                infoDelete = res.data.response;
            })
            .catch(() => {
                infoDelete = 8;
            })
            .finally(() => {
                funcion = "delete-task-detail";
                verify(infoDelete, funcion);
            });
    }

    const verify = (infoDelete, funcion) => {
        if (infoDelete === 1){
            setOpenDoneMsg(true);
            setBit(funcion);
        }else{
            setOpenErrorMsg(true);
        }
    }

    const setBit = (funcion) => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/insert-bit', {menu: menu, function: funcion, backup: taskLocationId, current: infoDelete,
            reason: reason, observations: textArea, user: userName, user_modified: userName, asignation_id: asignationId, location_id: locationId, project_id: projectId,
            task_id: taskId, answered_survey_id: null, audit_id: null, task_detail_id: taskDetailId, user_id: userId, repo_multi_id: null, task_location_id: taskLocationId})
            .then((res) => {
                bit = res.data.response;
                console.log(bit);
            })
            .catch(() => {
                bit = 8;
            })
            .finally(() => {
                if (bit === 1){
                    console.log("Bitacora guardada");
                    window.location.reload();
                }else{
                    console.log("Bitacora sin guardar");
                }
            });
    }

    const readReason = () =>{
        if (reason !== 10){
            droptask();
        }else {
            setReasonMessage(true);
        }
    }

    const readLength = () => {
        if (textArea.length >= 30){
            readReason();
        }else{
            setTextAreaMessage(true);
        }
    }

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={getPermits}>
                Eliminar
            </Button>
            <Snackbar open={openSnackBar} autoHideDuration={5000} onClose={closeSnackBar} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={closeSnackBar} severity="error" variant={"filled"}>
                    Se necesitan permisos de llave de caja
                </Alert>
            </Snackbar>
            <Snackbar open={openDoneMsg} autoHideDuration={5000} onClose={closeSnackBar} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={closeSnackBar} severity="success" variant={"filled"}>
                    Se realizó el borrado con éxito
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorMsg} autoHideDuration={5000} onClose={closeSnackBar} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={closeSnackBar} severity="error" variant={"filled"}>
                    Hubo un problema en el borrado
                </Alert>
            </Snackbar>
            <Snackbar open={textAreaMessage} autoHideDuration={5000} onClose={closeSnackBar} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={closeSnackBar} severity="info" variant={"filled"}>
                    El comentario debe tener al menos 30 caracteres
                </Alert>
            </Snackbar>
            <Snackbar open={reasonMessage} autoHideDuration={5000} onClose={closeSnackBar} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={closeSnackBar} severity="info" variant={"filled"}>
                    Se debe seleccionar un motivo
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Desea eliminar la tarea seleccionada?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Se eliminará de la asignación: "+asignationId+ " el Id Detalle Tarea: " +taskDetailId}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={getReasons} color="primary">
                        SI
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openReason}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"I N F O R M A C I O N"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Antes de continuar, por favor proporcionar los siguientes datos
                    </DialogContentText>
                    <form style={{ textAlign: "center" }}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">MOTIVOS</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason1}
                                onChange={setReasons}
                            >
                                {reasons.map(reason => {
                                    return(
                                        <MenuItem key={reason.id} value={reason.id}>{reason.name}</ MenuItem>
                                    )}
                                )}
                            </Select>
                        </FormControl>
                    </form>
                    <form
                        style={{
                            textAlign: "center",
                            alignItems: "center",
                            alignContent: "center"
                        }}
                    >
                        <FormControl
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                alignContent: "center"
                            }}
                        >
                            <textarea
                                style={{ width: "100%" , heigth: "100%"}}
                                className={"full-space"}
                                placeholder={"Descripcion (30 caracteres minimo)"}
                                onChange={changeTextArea}
                                value={textArea}
                                min="30"
                                maxLength="2000"
                                cols="100"
                                rows="10"
                                required={true}
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={readLength} color="primary">
                        Si
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
