import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import './Upaxer-Bootstrap.css';
import "./index.css";

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);