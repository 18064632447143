import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
let infopermit = 0;
let dataStatus = [];
let dataStatus2 = [];
let funcion = "";
let bit = 10;
let updstatus = 10;
let new_status = 50;
let statusValue = [];
let userName = "";

export default function PopUpDetail(props) {
    const classes = useStyles();
    let userId = props.props.userId;
    let projectId = props.props.projectId;
    let locationId = props.props.locationId;
    let asignationId = props.props.asignationId;
    let taskLocationId = props.props.taskLocationId;
    let taskId = props.props.taskId;
    let status = props.props.status;
    let statusB = status;
    let statusVista = props.props.statusVista;
    let menu = props.props.menu;

    const [stat, setStat] = React.useState(status);
    const [open, setOpen] = React.useState(false);
    const [openPop2, setOpenPop2] = React.useState(false);
    const [errorMes, setErrorMes] = React.useState(false);
    const [updateMsg, setUpdateMsg] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [textArea, setTextArea] = React.useState("");
    const [textAreaMessage, setTextAreaMessage] = React.useState(false);
    const [reasonMessage, setReasonMessage] = React.useState(false);
    const [reason, setReason] = React.useState(10);
    const [idEstatus0, setIdEstatus0] = React.useState(true);
    const [idEstatus1, setIdEstatus1] = React.useState(true);
    const [idEstatus2, setIdEstatus2] = React.useState(true);
    const [idEstatus3, setIdEstatus3] = React.useState(true);
    const [idEstatus4, setIdEstatus4] = React.useState(true);
    const [idEstatus5, setIdEstatus5] = React.useState(true);
    const [idEstatus6, setIdEstatus6] = React.useState(true);
    const [idEstatus7, setIdEstatus7] = React.useState(true);
    const [idEstatus8, setIdEstatus8] = React.useState(true);
    const [idEstatus9, setIdEstatus9] = React.useState(true);
    const [idEstatus10, setIdEstatus10] = React.useState(true);
    const [idEstatus11, setIdEstatus11] = React.useState(true);
    const [idEstatus12, setIdEstatus12] = React.useState(true);

    const handleOpenPop2 = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-reasons', {user_id: userId})
            .then((res) => {
                dataStatus2 = res.data.response;
            })
            .catch(() => {
                dataStatus2 = [];
            })
            .finally(() => {
                setOpen(false);
                setOpenPop2(true);
            });
    }

    const handleClose = () => {
        setOpen(false);
        setOpenPop2(false);
        setTextArea("");
        setReason(10);
        setStat( status);
    };

    const handleClose2 = () => {
        setErrorMes(false)
        setUpdateMsg(false);
        setUpdateError(false);
        setTextAreaMessage(false);
        setReasonMessage(false);
    }

    const beforeUpdate = (infopermit, userId,e) => {
        if(parseInt(infopermit) == parseInt(userId)){
            statusB = e.target.value;
            setStat(e.target.value);
            new_status = e.target.value;
            new_stat();
        }else{
            setErrorMes(true);
        }
    }

    const new_stat = () => {
        setOpen(true);
    }

    const setReasons = (e) => {
        setReason(e.target.value);
    }

    const compareStatus = () => {
        if (status === 3){
            setIdEstatus0(false);
            setIdEstatus2(false);
        }
        else if(status === 1){
            setIdEstatus0(false);
        }
        else if(status === 2){
            setIdEstatus0(false);
            setIdEstatus3(false);
        }
        else if(status === 4){
            setIdEstatus0(false);
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if (status === 5) {
            setIdEstatus0(false);
            setIdEstatus2(false);
        }
        else if (status === 6){
            setIdEstatus0(false);
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if(status === 7) {
            setIdEstatus0(false);
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if(status === 8){
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if(status === 9){
            setIdEstatus0(false);
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if(status === 10){
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if(status === 11){
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if(status === 12){
            setIdEstatus2(false);
            setIdEstatus3(false);
        }
        else if(status === 0){
            setIdEstatus2(false);
            setIdEstatus3(false);
        }else {
            setIdEstatus0(false);
            setIdEstatus1(false);
            setIdEstatus2(false);
            setIdEstatus3(false);
            setIdEstatus4(false);
            setIdEstatus5(false);
            setIdEstatus6(false);
            setIdEstatus7(false);
            setIdEstatus8(false);
            setIdEstatus9(false);
            setIdEstatus10(false);
            setIdEstatus11(false);
            setIdEstatus12(false);
        }
    }


    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-status', { project_id: projectId, user_id: userId, asignation_id: asignationId})
            .then((res) => {
                statusValue = res.data.response;
            })
            .catch(() => {
                statusValue = [];
            })
            .finally(() => {
                compareStatus();
                getAllStatus();
                getUserName();
            });
    }, [projectId, userId, asignationId]);

    const getAllStatus = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-all-status', {user_id: userId})
            .then((res) => {
                dataStatus = res.data.response;
                console.log(res.data.response);
            })
            .catch(() => {
                dataStatus = [];
            })
            .finally(() => {

            });
    }

    const getUserName = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-user-name', { user_id: userId})
            .then((res) => {
                userName = res.data.response[0].userName;
            })
            .catch(() => {
                userName = "";
            })
            .finally(() => {

            });
    }


    const getPermits = (e) => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-permits', {user_id: userId, project_id: projectId})
            .then((res) => {
                infopermit = res.data.response[0].userId;
            })
            .catch(() => {
                infopermit = 0;
            })
            .finally(() => {
                // compareStatus();
                beforeUpdate(infopermit, userId, e);
            });
    }

    const changeTextArea = (e) => {
        setTextArea(e.target.value);
    }

    const handleUpdate = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/update-status', {asignation_id: asignationId, status_id: new_status, task_location_id: taskLocationId, user: userName})
            .then((res) => {
                updstatus = res.data.response;
            })
            .catch(() => {
                updstatus = 8;
            })
            .finally(() => {
                funcion = "update-status-asignation";
                verify(updstatus, funcion);
            });
    }

    const verify = (updstatus, funcion) => {
        if (updstatus === 1){
            setBit(funcion);
            console.log("Actualizado");
            setUpdateMsg(true);
        }else{
            setUpdateError(true);
            console.log("Sin actualizar");
        }
    }

    const setBit = (funcion) => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/insert-bit', {menu: menu, function: funcion, backup: status, current: new_status,
            reason: reason, observations: textArea, user: userName, user_modified: userName, asignation_id: asignationId, location_id: locationId, project_id: projectId,
            task_id: null, answered_survey_id: null, audit_id: null, task_detail_id: null, user_id: userId, repo_multi_id: null, task_location_id: null})
            .then((res) => {
                bit = res.data.response;
                console.log(bit);
            })
            .catch(() => {
                bit = 8;
            })
            .finally(() => {
                if (bit === 1){
                    console.log("Bitacora guardada");
                    reload();
                    setOpenPop2(false);
                }else{
                    console.log("Bitacora sin guardar");
                    setOpenPop2(false);
                }
            });
    }

    const reload = () => {
        window.location.reload();
    }

    const readReason = () => {
        if (reason !== 10){
            handleUpdate();
        }else {
            setReasonMessage(true);
        }
    }

    const readLength = () => {
        if (textArea.length >= 30){
            readReason();
        }else{
            setTextAreaMessage(true);
        }
    }

    const obtenerEstatusDeCodigo = (codigoEstatusConversion) => {
        switch(codigoEstatusConversion) {
            case 0:
                return "ELIMINAR";
                break;
            case 1:
                return "LIBRE";
                break;
            case 2:
            return "SIN TERMINAR";
            break;
            case 3:
            return "TERMINADA";
            break;
            case 4:
            return "CANCELADA POR USUARIO";
            break;
            case 5:
            return "ABANDONADA";
            break;
            case 6:
            return "VENCIDA";
            break;
            case 7:
            return "EVIDENCIA EXTRAIDA POR USUARIO";
            break;
            case 8:
            return "DESACTIVADA";
            break;
            case 9:
            return "REPLANTADA";
            break;
            case 10:
            return "REHACER";
            break;
            case 11:
            return "REHACER ACTIVOS";
            break;
            case 12:
            return "SINCRONIZADO MULTIMEDIA";
            break;
            default:
                break;
        }
        return "";
    }


    return (
        <div>
            <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">ESTATUS</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={stat}
                    onChange={getPermits}
                >
                    <MenuItem disabled={idEstatus0} value={0}>ELIMINAR</MenuItem>
                    <MenuItem disabled={idEstatus1} value={1}>LIBRE</MenuItem>
                    <MenuItem disabled={idEstatus2} value={2}>SIN TERMINAR</MenuItem>
                    <MenuItem disabled={idEstatus3} value={3}>TERMINADA</MenuItem>
                    <MenuItem disabled={idEstatus4} value={4}>CANCELADA POR USUARIO</MenuItem>
                    <MenuItem disabled={idEstatus5} value={5}>ABANDONADA</MenuItem>
                    <MenuItem disabled={idEstatus6} value={6}>VENCIDA</MenuItem>
                    <MenuItem disabled={idEstatus7} value={7}>EVIDENCIA EXTRAIDA POR USUARIO</MenuItem>
                    <MenuItem disabled={idEstatus8} value={8}>DESACTIVADA</MenuItem>
                    <MenuItem disabled={idEstatus9} value={9}>REPLANTADA</MenuItem>
                    <MenuItem disabled={idEstatus10} value={10}>REHACER</MenuItem>
                    <MenuItem disabled={idEstatus11} value={11}>REHACER ACTIVOS</MenuItem>
                    <MenuItem disabled={idEstatus12} value={12}>SINCRINIZANDO MULTIMEDIA</MenuItem>
                </Select>
            </FormControl>
            <Snackbar open={errorMes} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="error" variant={"filled"}>
                    Se necesitan permisos de llave de caja
                </Alert>
            </Snackbar>
            <Snackbar open={updateMsg} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="success" variant={"filled"}>
                    Se realizó update con éxito
                </Alert>
            </Snackbar>
            <Snackbar open={updateError} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="error" variant={"filled"}>
                    Ocurrió un error en el update
                </Alert>
            </Snackbar>
            <Snackbar open={textAreaMessage} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="info" variant={"filled"}>
                    El comentario debe tener al menos 30 caracteres
                </Alert>
            </Snackbar>
            <Snackbar open={reasonMessage} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="info" variant={"filled"}>
                    Se debe seleccionar un motivo
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Desea cambiar el status?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Cambiar el estatus de la asignación: ${asignationId} con ID de Ubicación: ${locationId} a estatus: ${obtenerEstatusDeCodigo(stat)}`}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOpenPop2} color="primary">
                        Si
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openPop2}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"I N F O R M A C I O N"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Antes de continuar, por favor proporcionar los siguientes datos
                    </DialogContentText>
                    <form style={{ textAlign: "center" }}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">MOTIVOS</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason}
                                onChange={setReasons}
                            >
                                <MenuItem value={1}>Problemas con aplicacion</MenuItem>
                                <MenuItem value={2}>Problemas con sincronizacion</MenuItem>
                                <MenuItem value={3}>Problemas con el pax</MenuItem>
                                <MenuItem value={4}>Error de sistema</MenuItem>
                                <MenuItem value={5}>Error de usuario</MenuItem>
                                <MenuItem value={6}>Solicitud de negocios</MenuItem>
                                <MenuItem value={7}>Solicitud de cliente</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                    <form
                        style={{
                            textAlign: "center",
                            alignItems: "center",
                            alignContent: "center"
                        }}
                    >
                        <FormControl
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                alignContent: "center"
                            }}
                        >
                            <textarea
                                style={{ width: "100%" , heigth: "100%", min:"30"}}
                                className={"full-space"}
                                placeholder={"Descripcion (30 caracteres minimo)"}
                                onChange={changeTextArea}
                                value={textArea}
                                minLength="30"
                                maxLength="2000"
                                cols="100"
                                rows="10"
                                required={true}
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={readLength} color="primary">
                        Si
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
