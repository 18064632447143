import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

let infopermit = 0;
let dataStatus1 = [];
let dataStatus2 = [];
let funcion = "";
let respaldo = "";
let bit = 5;
let updstatus = 5;
let observationsPlus = "";
let statusValue = 20;
let userName = "";
let reason=10;

export default function PopUpAudit2(props) {
    const classes = useStyles();
    let userId = props.props.userId;
    let projectId = props.props.projectId;
    let locationId = props.props.locationId;
    let menu = props.props.menu;
    let asignationId = props.props.asignationId;
    let taskId = props.props.taskId;
    let answeredSurveyId = props.props.answeredSurveyId;
    const status = props.props.status;
    let stat = status;
    let auditId = props.props.auditId;
    let taskDetailId = props.props.taskDetailId;
    let taskLocationId = props.props.taskLocationId;
    let statusPay = props.props.statusPay;
    const [permitMessage, setPermitMessage] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialog2, setOpenDialog2] = React.useState(false);
    const [updateMsg, setUpdateMsg] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [textAreaMessage, setTextAreaMessage] = React.useState(false);
    const [reasonMessage, setReasonMessage] = React.useState(false);
    const [textArea, setTextArea] = React.useState("");
    const [reason1, setReason1] = React.useState(10);
    const [status1, setStatus1] = React.useState([]);
    const [st, setSt] = React.useState(stat);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-status', { project_id: projectId, user_id: userId, asignation_id: asignationId})
            .then((res) => {
                setStatus1(res.data.response);
                statusValue = res.data.response[0].id;
                console.log(status1);
                console.log(res)
                console.log(statusValue);
            })
            .catch(() => {
                setStatus1([]);
            })
            .finally(() => {
                respaldo = status;
                getAllStatus();
            });
    }, [projectId, userId, asignationId]);

    const getAllStatus = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-status-audit', {user_id: userId})
            .then((res) => {
                dataStatus1 = res.data.response;
            })
            .catch(() => {
                dataStatus1 = [];
            })
            .finally(() => {
                moreObservations();
                getUserName();
            });
    }

    const getUserName = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-user-name', { user_id: userId})
            .then((res) => {
                userName = res.data.response[0].userName;
            })
            .catch(() => {
                userName = "";
            })
            .finally(() => {

            });
    }

    const moreObservations = () => {
        if (statusPay === 0){
            observationsPlus = "El usuario estaba consciente que el estatus de pago era: 'En proceso de pago'";
        }else if (statusPay === 2){
            observationsPlus = "El usuario estaba consciente que el estatus de pago era: 'Pago realizado'";
        }else{
            observationsPlus = "";
        }
    }

    const beforeUpdate = (infopermit, userId,e) => {
        if(parseInt(infopermit) == parseInt(userId)){
            stat = e.target.value;
            setSt(e.target.value);
            setStatus1(e.target.value);
            new_stat();
        }else{
            setPermitMessage(true);
        }
    }

    const new_stat = () => {
        setOpenDialog(true);
    }

    const setReasons = (e) => {
        setReason1(e.target.value);
        reason=(e.target.value);
    }

    const getPermits = (e) => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-permits', {user_id: userId, project_id: projectId})
            .then((res) => {
                infopermit = res.data.response[0].userId;
            })
            .catch(() => {
                infopermit = 0;
            })
            .finally(() => {
                beforeUpdate(infopermit, userId, e);
            });
    }

    const closePermit = () => {
        setPermitMessage(false);
        setUpdateMsg(false);
        setUpdateError(false);
        setTextAreaMessage(false);
        setReasonMessage(false);
    }

    const closeDialog = () => {
        setOpenDialog(false);
        setOpenDialog2(false);
        setSt( status);

    }

    const openFPopUp = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-reasons', {user_id: userId})
            .then((res) => {
                dataStatus2 = res.data.response;
            })
            .catch(() => {
                dataStatus2 = [];
            })
            .finally(() => {
                setOpenDialog(false);
                setOpenDialog2(true);
            });
    }

    const closeDialog2 = () => {
        setOpenDialog(false);
        setOpenDialog2(false);
        setReason1(10);
        reason=(10);
        setTextArea("");

    }

    const changeTextArea = (e) =>{
        setTextArea(e.target.value)
    }

    const updateStatus = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/delete-audit', { audit_id: auditId, status_id: status1, user_name: userName})
            .then((res) => {
                updstatus=(res.data.response);
            })
            .catch(() => {
                updstatus=(8);
            })
            .finally(() => {
                setOpenDialog2(false);
                funcion = "update-status-audit";
                verify(updstatus, funcion, statusValue);
            });
    }

    const verify = (updstatus, funcion, statusValue) => {
        if (updstatus === 1){
            setBit(funcion, statusValue);
            setUpdateMsg(true);
        }else{
            setUpdateError(true);
        }
    }

    const setBit = (funcion) => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/insert-bit', {menu: menu, function: funcion, backup: status, current: status1,
            reason: reason, observations: textArea + observationsPlus, user: userName, user_modified: userName, asignation_id: asignationId, location_id: locationId, project_id: projectId,
            task_id: taskId, answered_survey_id: answeredSurveyId, audit_id: auditId, task_detail_id: taskDetailId, user_id: userId, repo_multi_id: null, task_location_id: taskLocationId})
            .then((res) => {
                bit = res.data.response;
            })
            .catch(() => {
                bit = 8;
            })
            .finally(() => {
                if (bit === 1){
                    console.log("Bitacora guardada");
                    window.location.reload();
                }else{
                    console.log("Bitacora sin guardar");
                }
            });
    }

    const readReason = () => {
        if (reason !== 10){
            updateStatus();
        }else {
            setReasonMessage(true);
        }
    }


    const readLength = () => {
        if (textArea.length >= 30){
            readReason();
        }else{
            setTextAreaMessage(true);
        }
    }

    const obtenerEstatusDeCodigo = (codigoEstatusConversion) => {
        switch(codigoEstatusConversion) {
            case 0:
                return "";
                break;
            case 1:
                return "PENDIENTE";
                break;
            default:
                break;
        }
        return "";
    }

    return(
        <td>
            <div>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-label">ESTATUS</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={st}
                        onChange={getPermits}
                    >
                        <MenuItem value={1}>PENDIENTE</MenuItem>
                    </Select>
                </FormControl>
                <Snackbar open={permitMessage} autoHideDuration={5000} onClose={closePermit} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closePermit} severity="error" variant={"filled"}>
                        Se necesitan permisos de llave de caja
                    </Alert>
                </Snackbar>
                <Snackbar open={updateMsg} autoHideDuration={5000} onClose={closePermit} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closePermit} severity="success" variant={"filled"}>
                        Se realizó update con éxito
                    </Alert>
                </Snackbar>
                <Snackbar open={updateError} autoHideDuration={5000} onClose={closePermit} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closePermit} severity="error" variant={"filled"}>
                        Ocurrió un error en el update
                    </Alert>
                </Snackbar>
                <Snackbar open={textAreaMessage} autoHideDuration={5000} onClose={closePermit} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closePermit} severity="info" variant={"filled"}>
                        El comentario debe tener al menos 30 caracteres
                    </Alert>
                </Snackbar>
                <Snackbar open={reasonMessage} autoHideDuration={5000} onClose={closePermit} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                    <Alert onClose={closePermit} severity="info" variant={"filled"}>
                        Se debe seleccionar un motivo
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openDialog}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Desea cambiar el estatus?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Cambiar el estatus correspondiente al ID de Asignación: ${asignationId} con ID de Auditoria: ${auditId} a estatus: ${obtenerEstatusDeCodigo(st)}`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={openFPopUp} color="primary">
                            Si
                        </Button>
                        <Button onClick={closeDialog} color="primary">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDialog2}
                    onClose={closeDialog2}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"I N F O R M A C I O N"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Antes de continuar, por favor proporcionar los siguientes datos
                        </DialogContentText>
                        <form style={{ textAlign: "center", width: '100%' }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">MOTIVOS</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={reason1}
                                    onChange={setReasons}
                                >
                                    {dataStatus2.map(reason => {
                                        return(
                                            <MenuItem key={reason.id} value={reason.id}>{reason.name}</ MenuItem>
                                        )}
                                    )}
                                </Select>
                            </FormControl>
                        </form>
                        <form
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                alignContent: "center"
                            }}
                        >
                            <FormControl
                                style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    alignContent: "center"
                                }}
                            >
                            <textarea
                                style={{ width: "100%" , heigth: "100%"}}
                                className={"full-space"}
                                placeholder={"Descripcion (30 caracteres minimo)"}
                                onChange={changeTextArea}
                                value={textArea}
                                minLength="30"
                                maxLength="2000"
                                cols="100"
                                rows="10"
                            />
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={readLength} color="primary">
                            Si
                        </Button>
                        <Button onClick={closeDialog2} color="primary">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </td>
    );
}
