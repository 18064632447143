import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Back from './Back';
import Spinner from './Spinner';
import AppsIcon from '@material-ui/icons/Apps';
import {filterBy} from "@progress/kendo-data-query";
import {Input} from "@progress/kendo-react-inputs";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../es.json';
loadMessages(esMessages, 'es-ES');


function AuditProjects(props) {
    // let userId = atob(props.match.params.userId);
    let userId = props.props.userId;
    const menu = "Proyectos Auditoria";
    const [data, setData] = React.useState([]);
    const [loader, setLoader] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(5);
    const [filter, setFilter] = React.useState({
        logic: "or",
        filters: [
            { field: "projectId", operator: "contains", value: "" },
            { field: "projectName", operator: "contains", value: "" },
            { field: "total", operator: "contains", value: "" }
        ]
    })

    useEffect(() => {
        axios.post( process.env.REACT_APP_API_URL + '/status/change/get-audit-projects', {userId: userId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false)
            });
    },[userId]);

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "projectId", operator: "contains", value: e.target.value },
                    { field: "projectName", operator: "contains", value: e.target.value },
                    { field: "total", operator: "contains", value: e.target.value }
                ]
            }
        );
    }

    const AuditP = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <Link to={{
                        pathname: '/Audit',
                        state: {
                            userId: userId,
                            // userName: userName,
                            projectId: props.dataItem.projectId,
                            projectName: props.dataItem.projectName
                        }
                    }}>
                        <AppsIcon style={{color: '#FF995B'}}>
                            <span style={{color: '#FF995B', cursor: 'pointer'}}>{props.dataItem.detail}</span>
                        </AppsIcon>
                    </Link>
                </div>
            </td>
        )
    }

    return (
        <LocalizationProvider language="es-ES">
            <IntlProvider locale="es" >
                <div >
                    {/*<h3 style={{textAlign: 'center', color: '#FF995B'}} >Proyectos Auditoria</h3>*/}
                    <Input
                        style={{width: '100%'}}
                        className={'full-space'}
                        placeholder={'Buscar'}
                        onChange={searchEvent}
                    />
                    <br/>
                    <br/>
                    <Grid
                        style={{
                            height: '100%',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            textAlignLast: 'center',
                        }}
                        data={filterBy(data, filter).slice(skip,take + skip)}
                        skip={skip}
                        take={take}
                        total={data.length}
                        pageable={{buttonCount: 4, pageSizes: true}}
                        onPageChange={pageChange}
                        resizable={true}
                    >
                        <GridColumn title={'Id'} field={'projectId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Proyecto'} field={'projectName'} width={'auto'}/>
                        <GridColumn title={'Total Ubicaciones'} field={'total'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Detalles'} cell={AuditP} filterable={false} width={'auto'}/>
                    </Grid>

                    <Back/>
                    {loader && <Spinner/>}
                </div>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default AuditProjects;