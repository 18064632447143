import React, {useEffect} from 'react';
import axios from 'axios';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Spinner from './Spinner';
import {filterBy} from "@progress/kendo-data-query";
import {Input} from "@progress/kendo-react-inputs";
import {IntlProvider, loadMessages, LocalizationProvider} from '@progress/kendo-react-intl';
import esMessages from '../es.json';
import PopUpAudit2 from "./PopUps/PopUpAudit2";
import TextField from "@material-ui/core/TextField";
import Back from "./Back";

loadMessages(esMessages, 'es-ES');

let userName = "";

function Audit(props) {
    let userId = props.location.state.userId;
    // let userName = props.location.state.userName;
    let projectId = props.location.state.projectId;
    let projectName = props.location.state.projectName;
    const menu = "Status Auditoria";
    const [data, setData] = React.useState([]);
    const [loader, setLoader] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(5);
    const [filter, setFilter] = React.useState({
        logic: "or",
        filters: [
            { field: "asignationId", operator: "contains", value: "" },
            { field: "taskNameId", operator: "contains", value: ""},
            { field: "answeredSurveyId", operator: "contains", value: "" },
            { field: "taskId", operator: "contains", value: "" },
            { field: "statusVista", operator: "contains", value: "" }
        ]
    })

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-audit',{user_id: userId, project_id: projectId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                getUserName();
                setLoader(false);
            });
    }, [userId, projectId]);

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const getUserName = () => {
        axios.post(process.env.REACT_APP_API_URL + '/status/change/get-user-name', { user_id: userId})
            .then((res) => {
                userName = res.data.response[0].userName;
            })
            .catch(() => {
                userName = "";
            })
            .finally(() => {
                console.log(userName);
            });
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "asignationId", operator: "contains", value: e.target.value },
                    { field: "taskName", operator: "contains", value: e.target.value },
                    { field: "answeredSurveyId", operator: "contains", value: e.target.value },
                    { field: "taskId", operator: "contains", value: e.target.value },
                    { field: "statusVista", operator: "contains", value: e.target.value }
                ]
            }
        );
    }

    const StatusAudit = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <PopUpAudit2
                        props={{
                            userId: userId,
                            projectId: projectId,
                            projectName: projectName,
                            userName: userName,
                            menu: menu,
                            asignationId: props.dataItem.asignationId,
                            taskId: props.dataItem.taskId,
                            taskNameId: props.dataItem.taskNameId,
                            answeredSurveyId: props.dataItem.answeredSurveyId,
                            status: props.dataItem.status,
                            auditId: props.dataItem.auditId,
                            taskDetailId: props.dataItem.taskDetailId,
                            taskLocationId: props.dataItem.taskLocationId,
                            locationId: props.dataItem.locationId,
                            statusPay: props.dataItem.statusPay
                        }}
                    />
                </div>
            </td>
        );
    }

    const Statuspayment = (props) => {
        let aux = props.dataItem;
        let pago = parseInt(aux.statusPay)
        if (pago === 0){
        return(
            <td>
                <div>
                    <TextField label={"En proceso de pago"} disabled={true}/>
                </div>
            </td>
        )
        }else if (pago === 2){
            return(
                <td>
                    <div>
                        <TextField label={"Pago realizado"} disabled={true}/>
                    </div>
                </td>
            )
        }else {
            return(
                <td>
                    <div style={{textAlign: 'center'}}>
                        <TextField style={{textAlign: 'center'}} label={""} disabled={true}/>
                    </div>
                </td>
            )
        }
    }

    return(
        <LocalizationProvider language="es-ES">
            <IntlProvider locale="es" >
                <div style={{width: '100%'}}>
                    <h5 style={{textAlign: 'center', alignContent: 'center', color: '#3F3344'}}>{"Proyecto : "+projectId + " - " + projectName}</h5>
                    <h3 style={{textAlign: 'center', alignContent: 'center', color: '#FF995B'}}>Estatus Auditoria</h3>
                    <Input
                        style={{width: '100%'}}
                        className={'full-space'}
                        placeholder={'Buscar'}
                        onChange={searchEvent}/>
                        <br/>
                        <br/>
                    <Grid
                        style={{
                            height: '500px',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            textAlignLast: 'center',
                        }}
                        data={filterBy(data, filter).slice(skip,take + skip)}
                        skip={skip}
                        take={take}
                        total={data.length}
                        pageable={{buttonCount: 4, pageSizes: true}}
                        onPageChange={pageChange}
                        resizable={true}
                    >
                        <GridColumn title={'Encuesta contestada'} field={'answeredSurveyId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Id asignación'} field={'asignationId'} filterable={false} width={'auto'} />
                        <GridColumn title={'Id tarea'} field={'taskId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Nombre tarea'} field={'taskNameId'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Estatus Vista'} field={'statusVista'} filterable={false} width={'auto'}/>
                        <GridColumn title={'Estatus Auditoria'} cell={StatusAudit} filterable={false} width={'auto'}/>
                        <GridColumn title={'Estatus pago'} cell={Statuspayment} filterable={false} width={'auto'}/>
                    </Grid>
                    <Back/>
                    {loader && <Spinner/>}
                </div>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default Audit;