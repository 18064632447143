import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import Projects from "../Projects";
import AuditProjects from "../AuditProjects";
import FontDownloadIcon from '@material-ui/icons/FontDownload';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function ScrollableTabsButtonPrevent(props) {
    let userId = atob(props.match.params.userId);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        let path = props.location.pathname;
        if (path === "/projects" && value !== 0) setValue(0);
        else if (path === "/auditProjects" && value !== 1) setValue(1);
    }, [value]);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    style={{backgroundColor: "#FFFFFF"}}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                >
                    <Tab label={"Asignación"} icon={<BrightnessAutoIcon />} aria-label="Asignación" {...a11yProps(0)} style={{color: "#FF995B"}}/>
                    <Tab label={"Auditoría"} icon={<FontDownloadIcon />} aria-label="Auditoria" {...a11yProps(1)} style={{color: "#FF995B"}}/>
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <h3 style={{textAlign: 'center', color: '#FF995B'}} >Proyectos Asignación</h3>
                <Projects props={{userId: userId}}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <h3 style={{textAlign: 'center', color: '#FF995B'}} >Proyectos Auditoria</h3>
                <AuditProjects props={{userId: userId}}/>
            </TabPanel>
        </div>
    );
}
