import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Projects from './components/Projects';
import AuditProjects from "./components/AuditProjects";
import Detail from './components/Detail';
import Audit from "./components/Audit";
import Task from "./components/Task";
import Tabs from "./components/Tabs/Tabs"

function App() {
    return(
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path={'/tabs/:userId'} exact={true} component={Tabs}/>
                    {/*<Route path={'/projects'} exact={true} component={Projects}/>*/}
                    {/*<Route path={'/auditProjects'} exact={true} component={AuditProjects}/>*/}
                    <Route path={'/detail'} component={Detail}/>
                    <Route path={'/audit'} component={Audit}/>
                    <Route path={'/task'} component={Task}/>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App;